<template>
  <b-card title="Promo Code List ">

     <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
    >
    <strong v-if="promoToDownload.length<=0">Loading Data...</strong>
    <strong v-else>Loading data finished, total data is {{promoToDownload.length}}</strong>
    <div style="display: table; margin: 0 auto;">
      <b-button :disabled="promoToDownload.length<=0" variant="primary" style="margin-top:20px">
        <download-excel  :data="promoToDownload" :fields="filter" name="Promo Code.xls" class="download-border">download</download-excel>  
      </b-button>
    </div>
    </b-modal> 

    <b-col cols="12">
      <b-row>
        <b-col cols="5">
          Start Date
          <b-form-datepicker  @input="(value) => fireFiltering('startDate', value)" :max="maxDate" v-model="startDate"/>
        </b-col>
        <b-col cols="5">
          End Date
          <b-form-datepicker  @input="(value) => fireFiltering('endDate', value)" :max="maxDate" v-model="endDate"/>
        </b-col>
        <b-col cols="2">
            <!-- <b-button variant="primary" style="margin-top:20px" @click="promptDownload=true">
              <feather-icon size="1x" icon="DownloadIcon" /> Download
            </b-button> -->
            <b-button variant="primary" style="margin-top:20px" @click="openDownloadModal">
              Download
            </b-button>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col cols="8"/>
        <b-col cols="4">
          <b-form-input
            @input="(value) => refCodeFiltering(value)"
            v-model="search"
            type="search"
            placeholder="Search by Reference Code"
          />
        </b-col>

        <b-col style="margin: 15px 0 0 0" cols="12">
          <b-table
            :items="promo"
            :fields="fields"
            :busy.sync="tableLoading"
            show-empty
            empty-text="Data Not Found"
          >
          <template #cell(usedDate)="row">
              {{ row.item.usedDate | formatDate }}
            </template>
          </b-table>
        </b-col>


        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import axios from '@/axios';
import lodash from 'lodash';

export default {
  data() {
    return {
      promoToDownload:[],
      fields: [
        { key: 'campaign', label: 'Campaign', filterable: true, sortable: true },
        { key: 'batch', label: 'Batch', filterable: true, sortable: true },
        { key: 'referenceCode', label: 'Reference Code', filterable: true, sortable: true },
        { key: 'status', label: 'Status', filterable: true, sortable: true },
        { key: 'usedDate', label: 'Use Date', filterable: true, sortable: true },
        { key: 'useBy', label: 'Customer', filterable: true, sortable: true },
        { key: 'cert', label: 'Cert', filterable: true, sortable: true },
      ],
      filter: {
        'Campaign'          : "campaign",
        'Reference Code'    : 'referenceCode',
        'Batch'             : 'batch',
        'Status'            : 'status',
        'Customer'          : 'useBy',
        'Date Used'         : 'usedDate',
        'Certificate'       : 'cert',
      },
      promptDownload: false,
      pageOptions: [5, 10, 20],
      search: '',
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      tableLoading: true,
    }
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters({
      promo : 'report/promo'
    }),
    ...mapState({
      filterOrder: (state) => {
        if (state.filter.filterContainer['report-promo-list'] !== undefined) {
          let orderFilter = state.filter.filterContainer['report-promo-list'];
          return orderFilter;
        }

        return {};
      },
    }),
    reactiveStartDate: {
      get() {
        if(this.filterOrder.startDate === undefined){
          this.filterOrder.startDate = this.startDate
        }
        return this.filterOrder.startDate;
      },
      set() {
        this.updateFilterOrder({
          name: 'report-promo-list',
          data: {
            startDate: this.filterOrder.startDate
          }
        });
      }
    },
    reactiveEndDate: {
      get() {
        if(this.filterOrder.endDate === undefined){
          this.filterOrder.endDate = this.endDate;
        }
        return this.filterOrder.endDate;
      },
      set() {
        this.updateFilterOrder({
          name: 'report-promo-list',
          data: {
            endDate: this.filterOrder.endDate
          }
        });
      }
    },  
  },
  methods: {
    ...mapActions({
      promoList : 'report/getPromoCode',
      getFilterOrder: 'filter/getFilter',
      updateFilterOrder: 'filter/updateFilter'
    }),
    openDownloadModal(){
      this.promptDownload=true;
      this.promoList({
        startDate : this.reactiveStartDate, 
        endDate : this.reactiveEndDate,
        forDownload:true
       
      }).then((data) => {
        this.promoToDownload=data.data;
      });

    },
    onPageChange(page) {
      this.currentPage=page;
      console.log('start', this.reactiveStartDate)
      console.log('filterOrder', this.filterOrder)

      this.promoList({
        startDate : this.filterOrder.startDate, 
        endDate : this.filterOrder.endDate,
        page: this.currentPage, 
        entry: this.perPage,
        refCode:this.search,
       
      }).then((data) => this.processMetadata(data.metadata));
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.tableLoading = false;
    },
    saveFilter(key, value) { //save filter yang terupdate
      let tempFilter = this.filterOrder; 

      if (value === null) {
        tempFilter[key] = undefined;
      } else {
        tempFilter[key] = value;
      }
      
      this.updateFilterOrder({ //save aja ini ke store filter sendiri
        name: 'report-promo-list',
        data: { ...tempFilter,entry: this.perPage,page:1 }
      });
    },
    fireFiltering(key, value) {
      this.saveFilter(key, value); //filter tanggal simpan
      
      let filter = this.filterOrder; //cari ini
      this.currentPage = 1
      this.busyLoading = true;
      this.promoList({ ...filter,refCode:this.search,entry: this.perPage,page:1})
      .then((data) => this.processMetadata(data.metadata));
    },
    refCodeFiltering: lodash.debounce(function (value){
      this.search=value;

      this.promoList({
        startDate : this.reactiveStartDate, 
        endDate : this.reactiveEndDate,
        page: 1, 
        entry: this.perPage,
        refCode:this.search,
       
      }).then((data) => this.processMetadata(data.metadata));
    },500)
  },
  created() {
    // Setup Store To Save Filtering Information
    this.getFilterOrder('report-promo-list');

    let now = new Date();
    let yearAgo = new Date(now.getFullYear()-1, now.getMonth()+1, now.getDate())
    let monthAgo= new Date();
    monthAgo.setMonth(monthAgo.getMonth()-1);

    
    this.maxDate = now;
    this.startDate = monthAgo;
    this.endDate = now
    
    document.title = 'Promo Code Report | RSP'
    this.promoList({
      startDate : this.reactiveStartDate, 
      endDate : this.reactiveEndDate,
      page: this.currentPage, 
      entry: this.perPage,
       
      }).then((data) => this.processMetadata(data.metadata));
  },
}
</script>
